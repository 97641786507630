// extracted by mini-css-extract-plugin
export var carousel = "leaderPage-module--carousel--LzIzc";
export var center = "leaderPage-module--center--ly-ve";
export var continueDown = "leaderPage-module--continueDown--c2OjG";
export var image = "leaderPage-module--image--RUO1Z";
export var mainContent = "leaderPage-module--mainContent--g-UJC";
export var mainText = "leaderPage-module--mainText--ia3XD";
export var socialIcons = "leaderPage-module--socialIcons--gVd9P";
export var textHeader = "leaderPage-module--textHeader--fiYvK";
export var textKeywords = "leaderPage-module--textKeywords--sQWbo";
export var textWhyUs = "leaderPage-module--textWhyUs--EWmeQ";
export var whyUs = "leaderPage-module--whyUs--JskZy";
export var zindex = "leaderPage-module--zindex--XxVkO";