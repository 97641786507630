import Head from '../Head';
import SocialIcons from '../SocialIcons';
import Footer from '../Footer';
import * as style from '../../styles/layouts/leaderPage.module.scss';
import * as style404 from '../../styles/layouts/page404.module.scss';
import * as generalStyle from '../../styles/styles.module.scss';
import Buldozer from '../shared/Buldozer';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'gatsby-plugin-react-i18next';

interface Props {
  children: React.ReactNode;
}

export default function Layout404() {
  return (
    <section>
      <section className={style.carousel}>
        <div className={classnames(generalStyle.center, style.zindex)}>
          <Head bigLogo={true} color="purple"></Head>
          <div className={style.whyUs}>
            <div className={style.mainText}>
              <div className={style404.subHeader}>Stránka nenalezena</div>
              <div className={style404.header}>404</div>
              <div>
                <Link className={style404.linkBack} to="/">
                  <img src="/assets/icons/arrow-left.svg" alt="Back to Index Page" /> na úvodní
                  stránku
                </Link>
              </div>
            </div>
          </div>

          <div className={style.socialIcons}>
            <SocialIcons />
          </div>

          <div className={style.continueDown}></div>
        </div>

        <div className={style.image}>
          <Buldozer></Buldozer>
        </div>
      </section>

      <section className={style.footerSection}>
        <Footer></Footer>
      </section>
    </section>
  );
}
