import * as style from '../../styles/shared/buldozer.module.scss';
import React from 'react';

export default function Buldozer() {
  return (
    <section className={style.buldozer}>
      <div className={style.stars}></div>
      <div className={style.smallCloud}></div>
      <div className={style.cloud}></div>
      <div className={style.sun}>
        <div className={style.icons}></div>
        <div className={style.buldozerIcon}></div>
      </div>
    </section>
  );
}
