import React from 'react';
import { Helmet } from 'react-helmet';
import Layout404 from '../components/layouts/404';

function Page404() {
  return (
    <div>
      <Helmet title="TALXIS - Stránka nenalezena"> </Helmet>
      <Layout404></Layout404>
    </div>
  );
}
export default Page404;
