// extracted by mini-css-extract-plugin
export var CloudsKeyframes = "buldozer-module--CloudsKeyframes--qV1zB";
export var SmallCloudsKeyframes = "buldozer-module--SmallCloudsKeyframes--knG6I";
export var StarsKeyFrames = "buldozer-module--StarsKeyFrames--KZ5wn";
export var buldozer = "buldozer-module--buldozer--8KrKr";
export var buldozerIcon = "buldozer-module--buldozerIcon--At3b2";
export var cloud = "buldozer-module--cloud--UeHaN";
export var crane = "buldozer-module--crane--gQq9J";
export var icons = "buldozer-module--icons--D3tYL";
export var smallCloud = "buldozer-module--smallCloud--FCLDQ";
export var stars = "buldozer-module--stars--sobq-";
export var sun = "buldozer-module--sun--89Owh";